import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    business: localStorage.getItem("business_info")
      ? localStorage.getItem("business_info")
      : {},
    empInfo: localStorage.getItem("employee_info")
      ? localStorage.getItem("employee_info")
      : {},
    loginMethods: () => {},
  },
  mutations: {
    setBusiness(state, payload) {
      state.business = payload;
    },
    setEmployeeInfo(state, payload) {
      state.empInfo = payload;
    },
    getGlobalLogin(state, payload) {
      state.loginMethods = payload;
    },
  },
  actions: {
    SET_BUSINESS_INFO(context, payload) {
      localStorage.setItem("business_info", payload);
      context.commit("setBusiness", payload);
    },
    SET_EMPLOYEE_INFO(context, payload) {
      localStorage.setItem("employee_info", payload);
      context.commit("setEmployeeInfo", payload);
    },
    GET_GLOBAL_LOGIN(context, payload) {
      context.commit("getGlobalLogin", payload);
    },
  },
  modules: {},
});
